<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <!-- Start Brand Area -->
    <br />
    <br />
    <div class="rn-brand-area pb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--30">
              <span class="subtitle">About the program</span>
              <h2 class="heading-title">Learn about the program</h2>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <v-row>
          <v-col lg="10" offset-lg="1">
            <div class="thumb position-relative">
              <CoolLightBox :items="items" :index="index" @close="index = null">
              </CoolLightBox>

              <div class="thumbnail position-relative">
                <div v-for="(image, imageIndex) in items" :key="imageIndex">
                  <img class="w-100" :src="image.thumb" alt="About Images" />
                  <a
                    @click="index = imageIndex"
                    class="video-popup position-top-center theme-color"
                    ><span class="play-icon"></span
                  ></a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="pt--120 no-spacer-inside-brand">
          <Brand />
        </div>
      </v-container>
    </div>
    <!-- End Brand Area -->

    <!-- Start Service Area  -->
    <div class="service-area creative-service-wrapper ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="text-center section-title mb--30">
              <span class="subtitle">What we can do for you</span>
              <h2 class="heading-title">Services provide for you.</h2>
              <p class="description">
                There are many variations of passages of Lorem Ipsum available,
                <br />
                but the majority have suffered alteration.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceTwo />
      </v-container>
    </div>
    <!-- Start Service Area  -->

    <!-- Start Testimonial Area  -->
    <div class="rn-testimonial-area bg_color--1 ptb--120">
      <v-container>
        <Testimonial />
      </v-container>
    </div>
    <!-- Start Testimonial Area  -->

    <!-- Start Contact Area  -->
    <div class="rn-contact-us ptb--120 bg_color--5" id="contact">
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../../assets/images/about/about-12.jpg"
              alt="contact images"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import Header from "../../components/header/Header";
import SliderOne from "../../components/slider/SliderOne";
import AboutThree from "../../components/about/AboutThree";
import ServiceTwo from "../../components/service/ServiceTwo";
import Portfolio from "../../components/portfolio/Portfolio";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/Blog";
import Footer from "../../components/footer/Footer";
import Contact from "../../components/contact/Contact";

export default {
  components: {
    Header,
    SliderOne,
    AboutThree,
    ServiceTwo,
    Portfolio,
    Testimonial,
    Blog,
    Footer,
    Contact,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/slexmedia-logo-White.png"),
      items: [
        {
          thumb: require("../../assets/images/blog/bl-big-02.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      index: null,
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `The Philosophy Of business analytics`,
        },
        {
          id: 2,
          icon: "check",
          desc: ` Fast-Track Your business`,
        },
        {
          id: 3,
          icon: "check",
          desc: `Lies And Damn Lies About business`,
        },
        {
          id: 4,
          icon: "check",
          desc: `Lies And Damn Lies About business`,
        },
      ],
    };
  },
};
</script>
